import React from 'react';
import { Button } from "@mui/material";
import { doc, setDoc, collection } from "firebase/firestore";

interface ResetChatButtonProps {
  user: any;
  firestore: any;
  chatType: string;
  hasCobra: boolean;
  clear: () => void;
  setConversationId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsNewConversation: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageThread: React.Dispatch<React.SetStateAction<any[]>>;
  setFeedback: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setAdditionalFeedback: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSentiment: React.Dispatch<React.SetStateAction<string | null>>;
  setFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setSentimentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAllFeedback: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetChatButton: React.FC<ResetChatButtonProps> = ({
  user,
  firestore,
  chatType,
  hasCobra,
  clear,
  setConversationId,
  setIsNewConversation,
  setMessageThread,
  setFeedback,
  setAdditionalFeedback,
  setSelectedSentiment,
  setFeedbackSubmitted,
  setSentimentVisible,
  setShowAllFeedback
}) => {
  const resetChat = async () => {
    if (!user?.uid) {
      console.error("User is not authenticated");
      return;
    }

    const userId = user.uid;
    const newConversationId = doc(collection(firestore, "users", userId, "convos")).id;

    try {
      // Create a new conversation document
      await setDoc(doc(firestore, "users", userId, "convos", newConversationId), {
        startTimestamp: new Date(),
        chatType: chatType,
        hasCobra: hasCobra,
        messages: [],
        feedbacks: [],
      });

      // Update user's conversationId
      await setDoc(doc(firestore, "users", userId), {
        conversationId: newConversationId
      }, { merge: true });

      // Clear the current chat state
      clear();

      // Update the conversationId state
      setConversationId(newConversationId);

      // Set the flag for a new conversation
      setIsNewConversation(true);

      // Reset other states as needed
      setMessageThread([]);
      setFeedback({});
      setAdditionalFeedback("");
      setSelectedSentiment(null);
      setFeedbackSubmitted(false);
      setSentimentVisible(true);
      setShowAllFeedback(false);

      console.log("Chat reset successfully. New conversation ID prepared:", newConversationId);

      // Refresh the window
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    } catch (error) {
      console.error("Error resetting chat: ", error);
    }
  };

  return (
    <Button 
      onClick={resetChat} 
      color="primary" 
      variant="contained" 
      sx={{ padding: "5px 10px 5px 10px", margin: "10px", bgcolor: 'primary.light' }}
    >
      Save/Reset
    </Button>
  );
};

export default ResetChatButton;