import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../contexts/Firebase';
import { Container, Typography, Card, List, ListItem, Button, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { format, isValid } from 'date-fns';
import ReactMarkdown from 'react-markdown';

interface Feedback {
  additionalFeedback: string;
  feedbackType: string;
  messageId: string;
  timestamp: any;
}

interface Conversation {
  id: string;
  chatType: string;
  feedbacks: Feedback[];
  hasCobra: boolean;
  messages: any[];
  startTimestamp: any;
  additionalFeedback: string;
  canContact: boolean;
  sentiment: string;
  messageCount: number; 
  feedbackCount: number; 
}

interface Plan {
  name: string;
  rate: number;
  deductible: number;
  outOfPocketMax: number;
}

const ConversationDetails = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { firestore, auth } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [, setRawData] = useState<any>(null);
  const navigate = useNavigate();

  const parseDate = (dateValue: any): Date | null => {
    if (dateValue instanceof Date) {
      return isValid(dateValue) ? dateValue : null;
    }
    
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      if (isValid(parsedDate)) {
        return parsedDate;
      }
      
      const parts = dateValue.split(' ');
      if (parts.length >= 6) {
        const [month, day, year, time, timezone] = parts;
        const [hours, minutes, seconds] = time.split(':');
        const newDateString = `${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezone.slice(3, -1)}`;
        const manuallyParsedDate = new Date(newDateString);
        return isValid(manuallyParsedDate) ? manuallyParsedDate : null;
      }
    }
    
    if (typeof dateValue === 'object' && dateValue !== null) {
      if (dateValue.__time__) {
        const firestoreDate = new Date(dateValue.__time__);
        return isValid(firestoreDate) ? firestoreDate : null;
      } else if (dateValue.seconds) {
        const firestoreDate = new Date(dateValue.seconds * 1000);
        return isValid(firestoreDate) ? firestoreDate : null;
      }
    }
    
    // console.error("Unable to parse date:", dateValue);
    return null;
  };

  const formatDate = (date: any): string => {
    if (!date) return 'Invalid Date';
    const parsedDate = parseDate(date);
    return parsedDate ? format(parsedDate, 'PPpp') : 'Invalid Date';
  };

  const getFeedbackColor = (feedbackType: string) => {
    switch (feedbackType) {
      case 'veryDissatisfied': return 'rgba(255, 0, 0, 0.1)';
      case 'dissatisfied': return 'rgba(255, 165, 0, 0.1)';
      case 'neutral': return 'rgba(255, 255, 0, 0.1)';
      case 'satisfied': return 'rgba(0, 128, 0, 0.1)';
      case 'verySatisfied': return 'rgba(0, 0, 255, 0.1)';
      case 'positive': return 'rgba(0, 255, 0, 0.1)'; // Added case for positive
      case 'negative': return 'rgba(255, 0, 0, 0.1)'; // Added case for negative
      default: return 'rgba(211, 211, 211, 0.1)';
    }
  };

  const getFeedbackBorderColor = (feedbackType: string) => {
    switch (feedbackType) {
      case 'positive': return 'green'; // Green border for positive feedback
      case 'negative': return 'red'; // Red border for negative feedback
      default: return 'transparent'; // No border for other feedback types
    }
  };

  const getFeedbackLabel = (feedbackType: string) => {
    switch (feedbackType) {
      case 'veryDissatisfied': return 'Very Dissatisfied';
      case 'dissatisfied': return 'Dissatisfied';
      case 'neutral': return 'Neutral';
      case 'satisfied': return 'Satisfied';
      case 'verySatisfied': return 'Very Satisfied';
      case 'positive': return 'Positive'; // Added case for positive
      case 'negative': return 'Negative'; // Added case for negative
      default: return 'Unknown';
    }
  };

  const renderRecommendedPlans = (plans: Plan[]) => {
    if (!plans || plans.length === 0) return null;  


    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" component="div">Recommended Plans</Typography>
        <Box sx={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Premium</TableCell>
                <TableCell>Deductible</TableCell>
                <TableCell>Out of Pocket Max</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan, index) => (
                <TableRow key={index}>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>${plan.rate.toFixed(2)}</TableCell>
                  <TableCell>{plan.deductible}</TableCell>
                  <TableCell>{plan.outOfPocketMax}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  };

  const renderHouseholdInfo = (householdData: any) => {
    if (!householdData || !householdData.members) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" component="div">Household Members</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Relationship</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(householdData.members).map(([key, member]: [string, any]) => (
              <TableRow key={key}>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.dob}</TableCell>
                <TableCell>{member.gender}</TableCell>
                <TableCell>{member.relationship}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("User is not authenticated");
        }

        const convosRef = collection(firestore, `users/${currentUser.uid}/convos`);
        const convosSnap = await getDocs(convosRef);

        const convoDoc = convosSnap.docs.find(doc => doc.id === conversationId);

        if (!convoDoc) {
          throw new Error("Conversation not found");
        }

        const convoData = convoDoc.data();
        setRawData(convoData);
        console.log("Raw conversation data:", convoData);

        // Fetch all messages for the user
        const messagesRef = collection(firestore, `users/${currentUser.uid}/messages`);
        const messagesSnap = await getDocs(messagesRef);

        console.log("Number of messages fetched:", messagesSnap.size);

        const messageTextMap = new Map();
        messagesSnap.forEach(doc => {
          const messageData = doc.data();
          console.log("Message data:", messageData);
          if (messageData.text) {
            messageTextMap.set(messageData.text, doc.id);
          } else {
            console.warn("Message without text field:", doc.id, messageData);
          }
        });

        console.log("Message Text Map size:", messageTextMap.size);

        const formattedConvo: Conversation = {
          id: convoDoc.id,
          chatType: convoData.chatType || 'Unknown',
          hasCobra: convoData.hasCobra || false,
          startTimestamp: convoData.startTimestamp,
          messages: (convoData.messages || []).map((message: any) => {
            let messageRefId = 'Not found';
            if (message.text) {
              messageRefId = messageTextMap.get(message.text) || 'Not found';
              if (messageRefId === 'Not found') {
                console.warn(`No matching message found for text: ${message.text.substring(0, 50)}...`);
              }
            } else {
              console.warn("Message in conversation without text field:", message);
            }
            return {
              ...message,
              data: message.data || {},
              dynamicTemplate: message.dynamicTemplate || '',
              text: message.text || 'No text available',
              timestamp: message.timestamp,
              type: message.type || 'Unknown',
              user: message.user,
              id: message.id,
              messageRefId: messageRefId,
            };
          }),
          feedbacks: (convoData.feedbacks || []).map((feedback: any) => ({
            ...feedback,
            timestamp: feedback.timestamp,
          })),
          additionalFeedback: convoData.additionalFeedback || '',
          canContact: convoData.canContact || false,
          sentiment: convoData.sentiment || '',
          messageCount: (convoData.messages || []).length,
          feedbackCount: (convoData.feedbacks || []).length, 
        };

        console.log("Formatted conversation:", formattedConvo);
        setConversation(formattedConvo);
      } catch (err) {
        console.error("Error fetching conversation:", err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [firestore, auth, conversationId]);


  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  if (!conversation) {
    return <Typography>No conversation data found</Typography>;
  }

  const CustomParagraph = (props: any) => {
    return <Typography variant="body1" component="p" sx={{ mb: 1, p: 2, borderRadius:10 }} {...props} />;
  };

  return (
    <Container sx={{ py: 4 }}>
      <Button onClick={() => navigate('/admin/jadmin')} variant="outlined" sx={{ mb: 2 }}>
        Back to Conversations
      </Button>
      <Typography variant="h2" component="h1" sx={{ pb: 2 }}>Conversation Details</Typography>
      <Card sx={{ p: 5, mb: 2, br: 10 }}>
        <Box component="dl">
          <Box component="dt"><Typography variant="body1" component="span"><strong>ID: </strong>{conversation.id}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Message Count: </strong>{conversation.messageCount}</Typography></Box> 
          <Box component="dt"><Typography variant="body1" component="span"><strong>Feedback Count: </strong>{conversation.feedbackCount}</Typography></Box> 
          <Box component="dt"><Typography variant="body1" component="span"><strong>Chat Type: </strong>{conversation.chatType}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Has Cobra: </strong>{conversation.hasCobra ? 'Yes' : 'No'}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Start Timestamp: </strong>{formatDate(conversation.startTimestamp)}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Can Contact: </strong>{conversation.canContact ? 'Yes' : 'No'}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Last Sentiment: </strong>{conversation.sentiment}</Typography></Box>
          <Box component="dt"><Typography variant="body1" component="span"><strong>Final Feedback: </strong>{conversation.additionalFeedback}</Typography></Box>
        </Box>
      </Card>
      <Typography variant="h4" component="h2">Messages</Typography>
      <List>
      {conversation.messages.map((message: any, index: number) => (
          <ListItem key={index} alignItems="flex-start">
            <Card 
              sx={{ 
                width: '100%', 
                p: 3, 
                mb: 2, 
                bgcolor: message.user ? 'rgba(0, 0, 255, 0.2)' : 'rgba(0, 128, 0, 0.2)' 
              }}
            >
              <Box sx={{ bgcolor: '#ffffff', p: 1, pr: 2, pl: 2, pt: .5, pb: .5, m: 2, boxShadow: 'none', borderRadius:2 }}>
              <ReactMarkdown components={{
                  p: ({node, ...props}) => <CustomParagraph {...props} /> 
                }}>
                  {message.text || 'No text available'}
                </ReactMarkdown>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {message.data && message.data.oldInsuranceDetails && (
                    <Box sx={{ flex: 1, mr: 2, p:2 }}>
                      <Typography variant="subtitle1" component="div">
                        <strong>Old Insurance Details:</strong>
                      </Typography>
                      <Typography variant="caption" component="div">
                        Zipcode: {message.data.oldInsuranceDetails.zipcode}
                      </Typography>
                      <Typography variant="caption" component="div">
                        Effective Date: {message.data.oldInsuranceDetails.effectiveDate}
                      </Typography>
                      {renderHouseholdInfo(message.data.oldInsuranceDetails.household)}
                    </Box>
                  )}
                  {message.data && message.data.newInsuranceDetails && (
                    <Box sx={{ flex: 1, p:2 }}>
                      <Typography variant="subtitle1" component="div">
                        <strong>New Insurance Details:</strong>
                      </Typography>
                      <Typography variant="caption" component="div">
                        Zipcode: {message.data.newInsuranceDetails.zipcode}
                      </Typography>
                      <Typography variant="caption" component="div">
                        Effective Date: {message.data.newInsuranceDetails.effectiveDate}
                      </Typography>
                      {renderHouseholdInfo(message.data.newInsuranceDetails.household)}
                    </Box>
                  )}
                </Box>
                {message.data && (message.data.oldPreferences || message.data.newPreferences) && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', mt: 2, width: '100%' }}>
                    {message.data.oldPreferences && (
                      <Box sx={{ flex: 1, mr: 2 }}>
                        <Typography variant="subtitle1" component="div">
                          <strong>Old Preferences</strong>
                        </Typography>
                        <Table size="small">
                          <TableBody>
                            {message.data.oldPreferences.lowPremium !== undefined && (
                              <TableRow>
                                <TableCell>Low Premium</TableCell>
                                <TableCell>{message.data.oldPreferences.lowPremium.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.oldPreferences.comprehensiveCoverage !== undefined && (
                              <TableRow>
                                <TableCell>Comprehensive Coverage</TableCell>
                                <TableCell>{message.data.oldPreferences.comprehensiveCoverage.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.oldPreferences.dental !== undefined && (
                              <TableRow>
                                <TableCell>Dental</TableCell>
                                <TableCell>{message.data.oldPreferences.dental.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.oldPreferences.lowDeductible !== undefined && (
                              <TableRow>
                                <TableCell>Low Deductible</TableCell>
                                <TableCell>{message.data.oldPreferences.lowDeductible.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.oldPreferences.mentalHealth !== undefined && (
                              <TableRow>
                                <TableCell>Mental Health</TableCell>
                                <TableCell>{message.data.oldPreferences.mentalHealth.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.oldPreferences.planType && (
                              <>
                                {message.data.oldPreferences.planType.hmo !== undefined && (
                                  <TableRow>
                                    <TableCell>HMO</TableCell>
                                    <TableCell>{message.data.oldPreferences.planType.hmo.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                                {message.data.oldPreferences.planType.ppo !== undefined && (
                                  <TableRow>
                                    <TableCell>PPO</TableCell>
                                    <TableCell>{message.data.oldPreferences.planType.ppo.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                                {message.data.oldPreferences.planType.shortterm !== undefined && (
                                  <TableRow>
                                    <TableCell>Short Term</TableCell>
                                    <TableCell>{message.data.oldPreferences.planType.shortterm.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                              </>
                            )}
                            {message.data.oldPreferences.vision !== undefined && (
                              <TableRow>
                                <TableCell>Vision</TableCell>
                                <TableCell>{message.data.oldPreferences.vision.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    )}
                    {message.data.newPreferences && (
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" component="div">
                          <strong>New Preferences</strong>
                        </Typography>
                        <Table size="small">
                          <TableBody>
                            {message.data.newPreferences.lowPremium !== undefined && (
                              <TableRow>
                                <TableCell>Low Premium</TableCell>
                                <TableCell>{message.data.newPreferences.lowPremium.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.newPreferences.comprehensiveCoverage !== undefined && (
                              <TableRow>
                                <TableCell>Comprehensive Coverage</TableCell>
                                <TableCell>{message.data.newPreferences.comprehensiveCoverage.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.newPreferences.dental !== undefined && (
                              <TableRow>
                                <TableCell>Dental</TableCell>
                                <TableCell>{message.data.newPreferences.dental.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.newPreferences.lowDeductible !== undefined && (
                              <TableRow>
                                <TableCell>Low Deductible</TableCell>
                                <TableCell>{message.data.newPreferences.lowDeductible.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.newPreferences.mentalHealth !== undefined && (
                              <TableRow>
                                <TableCell>Mental Health</TableCell>
                                <TableCell>{message.data.newPreferences.mentalHealth.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                            {message.data.newPreferences.planType && (
                              <>
                                {message.data.newPreferences.planType.hmo !== undefined && (
                                  <TableRow>
                                    <TableCell>HMO</TableCell>
                                    <TableCell>{message.data.newPreferences.planType.hmo.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                                {message.data.newPreferences.planType.ppo !== undefined && (
                                  <TableRow>
                                    <TableCell>PPO</TableCell>
                                    <TableCell>{message.data.newPreferences.planType.ppo.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                                {message.data.newPreferences.planType.shortterm !== undefined && (
                                  <TableRow>
                                    <TableCell>Short Term</TableCell>
                                    <TableCell>{message.data.newPreferences.planType.shortterm.toFixed(1)}</TableCell>
                                  </TableRow>
                                )}
                              </>
                            )}
                            {message.data.newPreferences.vision !== undefined && (
                              <TableRow>
                                <TableCell>Vision</TableCell>
                                <TableCell>{message.data.newPreferences.vision.toFixed(1)}</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    )}
                  </Box>
                )}
                </Box>
                {message.data && message.data.recommendedPlans && renderRecommendedPlans(message.data.recommendedPlans)}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pt:3 }}>
              <Typography variant="caption" component="div">
                  <strong>Message </strong>#{index + 1}
                </Typography>
                <Typography variant="caption" component="div">
                  <strong>Message ID:</strong> {message.id}
                </Typography>
                <Typography variant="caption" component="div">
                  <strong>Feedback ID:</strong> {message.messageRefId}
                </Typography>
                {message.selectedPlanId && (
                  <Typography variant="caption" component="div">
                    <strong>Selected Plan ID:</strong> {message.selectedPlanId}
                  </Typography>
                )}
                <Typography variant="caption" component="div">
                  <strong>Timestamp:</strong> {formatDate(message.timestamp)}
                </Typography>
                </Box>
            </Card>
          </ListItem>
        ))}
      </List>
      <Typography variant="h4" component="h2">Feedback</Typography>
      <List>
        {conversation.feedbacks.map((feedback, index) => (
          <ListItem key={index}>
            <Card 
              sx={{ 
                width: '100%', 
                p: 2, 
                mb: 2, 
                bgcolor: getFeedbackColor(feedback.feedbackType),
                border: `2px solid ${getFeedbackBorderColor(feedback.feedbackType)}`
              }}
            >
              <Box>
              {feedback.additionalFeedback && feedback.additionalFeedback.trim() !== '' && (
                  <Typography variant="body1" component="div">{feedback.additionalFeedback}</Typography>
                )}<Typography variant="caption" component="div"><strong>Sentiment:</strong> {getFeedbackLabel(feedback.feedbackType)}</Typography>
                <Typography variant="caption" component="div"><strong>Message ID:</strong> {feedback.messageId}</Typography>
                <Typography variant="caption" component="div"><strong>Timestamp:</strong> {formatDate(feedback.timestamp)}</Typography>
              </Box>
            </Card>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default ConversationDetails;