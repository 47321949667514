import React, { useEffect, useCallback } from 'react';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, doc, orderBy, query, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import moment from "moment";

interface RecommendationsProps {
    sendPrompt: (message: string) => void;
}

const Recommendations: React.FC<RecommendationsProps> = ({ sendPrompt }) => {
    const { firestore } = useFirebase();
    const { user, answers, userData } = useAuth();
    const conversationId = userData?.get("conversationId");
    const [plans, plansLoading, plansError] = useCollection<QuotitHealthPlanDetails>(
        query<QuotitHealthPlanDetails>(
            collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`).withConverter(QuotitPlanConverter),
            orderBy("recommendation.internalScore", "desc"),
        )
    );

    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });

    const saveRecommendedPlans = useCallback(async (recommendedPlans: QuotitHealthPlanDetails[]) => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }

        try {
            const topThreePlans = recommendedPlans
                .filter(plan => plan.insuranceType === "HealthOnExchange")
                .slice(0, 3);

            const answersRef = doc(firestore, `users/${user.uid}/answers/${user.uid}`);
            await updateDoc(answersRef, {
                recommendedPlans: topThreePlans
            });

            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoSnapshot = await getDoc(convoRef);

            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];

                if (messages.length > 0) {
                    const recentMessage = messages[messages.length - 1];

                    recentMessage.data = {
                        ...recentMessage.data,
                        recommendedPlans: topThreePlans.map(plan => ({
                            id: plan.id,
                            name: plan.name,
                            rate: plan.rate,
                            deductible: plan.benefits.find(benefit => 
                                benefit.enum === "ACA1MedicalDeductible" || 
                                benefit.enum === "ACA1MedicalDrugDeductible" || 
                                benefit.enum === "AnnualDeductible"
                            )?.inNetwork,
                            outOfPocketMax: plan.benefits.find(benefit => 
                                benefit.enum === "ACA1EHBDrugOOPMax" || 
                                benefit.enum === "MaximumAnnualCopay"
                            )?.inNetwork
                        }))
                    };

                    await updateDoc(convoRef, {
                        messages: messages
                    });

                    console.log("Top 3 recommended plans added to the most recent message data");
                } else {
                    console.error("No messages found in the conversation");
                }
            } else {
                console.error("Conversation document does not exist");
            }
        } catch (error) {
            console.error("Error saving recommended plans:", error);
        }
    }, [firestore, user, conversationId]);

    const setSelectedPlan = async (planId: string, planDetails: any) => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }

        try {
            await setDoc(doc(firestore, "users", user.uid, "settings", "chat"), {
                selectedPlan: planId,
                selectedPlanDetails: planDetails,
            }, { merge: true });

            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoSnapshot = await getDoc(convoRef);

            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];

                if (messages.length > 0) {
                    const lastMessage = messages[messages.length - 1];
                    lastMessage.selectedPlanId = planId;

                    await updateDoc(convoRef, {
                        messages: messages
                    });

                    console.log(`Selected plan (${planId}) added to the most recent message`);
                } else {
                    console.warn("No messages found in the conversation");
                }
            } else {
                console.error("Conversation document does not exist");
            }
        } catch (error) {
            console.error("Error updating selected plan:", error);
        }
        
        sendPrompt("I have selected a plan, provide a quick overview of the details");
    }

    useEffect(() => {
        if (plans && plans.docs.length > 0) {
            const recommendedPlans = plans.docs.map(doc => doc.data());
            saveRecommendedPlans(recommendedPlans);
        }
    }, [plans, saveRecommendedPlans]);

    if (plansLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        )
    }
    if (plansError) {
        return (
            <Box>
                <Typography>Error loading plans</Typography>
            </Box>
        )
    }

    const topThreePlans = plans?.docs
        .filter((plan) => plan.get('insuranceType') === "HealthOnExchange")
        .slice(0, 3);

    return (
        <Box>
            <Typography>Recommended Plans</Typography>
            <Box>
                {topThreePlans?.map((doc) => {
                    const plan = doc.data();
                    const deductible = plan.benefits.filter(benefit => benefit.enum === "ACA1MedicalDeductible" || benefit.enum === "ACA1MedicalDrugDeductible" || benefit.enum === "AnnualDeductible")[0];
                    const oopMax = plan.benefits.filter(benefit => benefit.enum === "ACA1EHBDrugOOPMax" || benefit.enum === "MaximumAnnualCopay")[0];
                    const planDetails = {
                        name: plan.name,
                        rate: plan.rate,
                        deductible: deductible.inNetwork,
                        oopMax: oopMax.inNetwork
                    };
                    return (
                        <Box key={doc.id} sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            backgroundColor: "beige.main",
                            p: 4,
                            borderRadius: "17px 17px 2px 17px",
                            maxWidth: { xs: "100%", sm: "75%" },
                            margin: "1em"
                        }}>
                            <Typography>{plan.name}</Typography>
                            <Typography>Premium: {plan.rate}</Typography>
                            <Typography>Deductible: {deductible.inNetwork}</Typography>
                            <Typography>Out of Pocket Max: {oopMax.inNetwork}</Typography>
                            <Button variant="contained" color="primary" onClick={() => { setSelectedPlan(plan.id, planDetails) }}>Select</Button>
                        </Box>
                    )
                })}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography>Nothing strike your fancy?</Typography>
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        await loadRates({
                            variables: {
                                effectiveDate: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                            },
                            fetchPolicy: "network-only"
                        });
                        sendPrompt("I would like to update my preferences.");
                    }}
                >
                    Update Preferences
                </Button>
                <br />
            </Box>
            {rateQuery.loading && <Typography>Refreshing Plans... <br /> <br /><CircularProgress /> </Typography>}
        </Box>
    );
};

export default Recommendations;