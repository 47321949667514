import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";

const EnrollNow: React.FC = () => {
    const { firestore } = useFirebase();
    const { user, answers } = useAuth();
    const [userSettings, ,] = useDocument<any>(
        doc(firestore, "users", user?.uid || "a", "settings", "chat"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    )
    const [plan, planLoading, planError] = useDocument<QuotitHealthPlanDetails>(
        doc(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans/${userSettings?.get("selectedPlan") || "a"}`).withConverter(QuotitPlanConverter),
        {
            snapshotListenOptions: {},
        });
    const [fetchEnrollmentUrl, enrollmentUrl] = useLazyQuery<{ generateEnrollmentUrl: { redirectUrl: string } }, { effectiveDate: string, planId: string, carrierId: string }>(Queries.generateEnrollmentUrl, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (plan && answers) {
            fetchEnrollmentUrl({
                variables: {
                    effectiveDate: answers.get("insuranceDetails.effectiveDate"),
                    planId: plan.id.toString(),
                    carrierId: plan.get("carrierId")?.toString() || "",
                },
            })
        }
    }, [answers, fetchEnrollmentUrl, plan])

    async function startEnrollment() {
        if (!userSettings?.get("enrollmentStatus")) {
            await setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
                enrollmentStatus: "started",
            }, { merge: true });
        }
    }

    if (planLoading) {
        return (
            <Box>
                Loading <CircularProgress />
            </Box>
        )
    }
    if (planError) {
        return (
            <Box>
                <Typography>Error loading plan</Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Typography>Enrolling in: {plan?.data()?.name}</Typography>
            <Button 
                variant="contained" 
                color="primary" 
                disabled={!enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl || !enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl.includes("http")} 
                onClick={startEnrollment}
            >
                Start Enrollment
            </Button>
        </Box>
    )
}

export default EnrollNow;