import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Select, MenuItem, TextField, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CircularProgress } from "@mui/material";
import { Edit, Check, Cancel } from "@mui/icons-material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import Household from "components/HealthPlan/Household";
import { useRemoteConfig } from "contexts/RemoteConfig";

interface EnrollmentInformationProps {
    sendPrompt: (message: string) => void;
}

const EnrollmentInformation: React.FC<EnrollmentInformationProps> = ({ sendPrompt }) => {
    const { firestore } = useFirebase();
    const { user, answers, userData } = useAuth();
    const [conversationId] = useState<string | null>(userData?.get("conversationId"));
    const ideon = useRemoteConfig("ideon").asBoolean();
    const [members, setMembers] = useState<{ [key: string]: any }>({});
    const [editHouseholdOpen, setEditHouseholdOpen] = useState(false);
    const [editZipcode, setEditZipcode] = useState(false);
    const [editCounty, setEditCounty] = useState(false);
    const [zipcode, setZipcode] = useState("");
    const [county, setCounty] = useState("");
    const [editEffectiveDate, setEditEffectiveDate] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState("");
    const [GET_GEOGRAPHY] = useLazyQuery(Queries.GET_GEOGRAPHY);
    const [counties, setCounties] = useState<{ text: string, value: { fips: string, name: string, state: string, zipcode: string } }[]>([]);
    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string, source?: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });
    const [promptSent, setPromptSent] = useState(true);
    const [native, setNative] = useState(false);
    const [checkPremiumTaxCredits, setCheckPremiumTaxCredits] = useState(false);
    const [errors, setErrors] = useState<{ native?: string, premiumTaxCredits?: string }>({});
    const [oldInsuranceData, setOldInsuranceData] = useState<any>(null);

    useEffect(() => {
        const mems = answers?.get('insuranceDetails.household.members');
        if (mems === undefined) {
            setMembers({});
        } else {
            setMembers(mems);
        }
        if (answers?.get("insuranceDetails.zipcode")) {
            setZipcode(answers?.get("insuranceDetails.zipcode"));
        }
        if (answers?.get("insuranceDetails.effectiveDate")) {
            setEffectiveDate(answers?.get("insuranceDetails.effectiveDate"));
        }
        if (answers?.get("insuranceDetails.native")) {
            setNative(answers?.get("insuranceDetails.native"));
        }
        if (answers?.get("insuranceDetails.checkPremiumTaxCredits")) {
            setCheckPremiumTaxCredits(answers?.get("insuranceDetails.checkPremiumTaxCredits"));
        }
    }, [answers]);

    useEffect(() => {
        const fetchInitialInsuranceData = async () => {
            if (user?.uid) {
                const insuranceDetailsRef = doc(firestore, `users/${user.uid}/answers/${user.uid}`);
                const insuranceDataSnap = await getDoc(insuranceDetailsRef);
                const initialInsuranceData = insuranceDataSnap.data()?.insuranceDetails || {};
                setOldInsuranceData(initialInsuranceData);
            }
        };
        fetchInitialInsuranceData();
    }, [user, firestore]);

    const saveInsuranceData = async (oldData: any, newData: any) => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }

        try {
            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoSnapshot = await getDoc(convoRef);

            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];

                if (messages.length > 0) {
                    const recentMessage = messages[messages.length - 1];
                    recentMessage.data = {
                        ...recentMessage.data,
                        oldInsuranceDetails: oldData,
                        newInsuranceDetails: newData
                    };
                    await updateDoc(convoRef, { messages: messages });
                    console.log("Old and new insurance data added to the most recent message data");
                } else {
                    console.error("No messages found in the conversation");
                }
            } else {
                console.error("Conversation document does not exist");
            }
        } catch (error) {
            console.error("Error saving insurance data:", error);
        }
    };

    const updateAnswers = async (key: string, value: any) => {
        if (answers) {
            await updateDoc(answers.ref, { [key]: value });
            const insuranceDetailsRef = doc(firestore, `users/${user?.uid}/answers/${user?.uid}`);
            const newInsuranceDataSnap = await getDoc(insuranceDetailsRef);
            const newInsuranceData = newInsuranceDataSnap.data()?.insuranceDetails || {};
            setOldInsuranceData(newInsuranceData);
        }
    }

    const getCounties = async (zipcode: string) => {
        const counties = await GET_GEOGRAPHY({
            query: Queries.GET_GEOGRAPHY,
            variables: { zipcode: zipcode }
        })
        return setCounties(counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => {
            return { text: county.name, value: county }
        }));
    }

    return (
        <Box sx={{
            display: "block",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginLeft: "auto",
            backgroundColor: "beige.main",
            p: 4,
            maxWidth: { xs: "100%", sm: "75%" },
            borderRadius: "17px 17px 2px 17px",
        }}>
            <Typography>Your Enrollment Details</Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center"
                }}>
                    {!editEffectiveDate && (
                        <>
                            <Typography>Effective Date: {moment(answers?.get("insuranceDetails.effectiveDate")).format("MMMM D")}</Typography>
                            <IconButton onClick={() => { setEditEffectiveDate(true) }}><Edit fontSize="small" /></IconButton>
                        </>
                    )}
                    {editEffectiveDate && (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                            <Typography>Effective Date: </Typography>
                            <Select value={effectiveDate} onChange={(e) => { setEffectiveDate(e.target.value) }}>
                                {Array.from(Array(4).keys()).map((i) => {
                                    const date = moment().add(i + 1, "months").startOf("month");
                                    return (
                                        <MenuItem key={date.format("YYYY-MM-DD")}
                                            value={date.format("YYYY-MM-DD")}>
                                            {date.format("MMMM D")}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <IconButton onClick={() => { setPromptSent(false); updateAnswers("insuranceDetails.effectiveDate", effectiveDate); setEditEffectiveDate(false) }}><Check fontSize="small" /></IconButton>
                            <IconButton onClick={() => { setEditEffectiveDate(false) }}><Cancel fontSize="small" /></IconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center"
                }}>
                    {!editZipcode && (
                        <>
                            <Typography>Zipcode: {answers?.get("insuranceDetails.zipcode")}</Typography>
                            <IconButton onClick={() => { setEditZipcode(true) }}><Edit fontSize="small" /></IconButton>
                        </>
                    )}
                    {editZipcode && (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                            {!editCounty && (
                                <>
                                    <Typography>Zipcode: </Typography>
                                    <TextField value={zipcode} onChange={(e) => { setZipcode(e.target.value) }}></TextField>
                                    <IconButton onClick={() => { getCounties(zipcode); setEditCounty(true) }}><Check fontSize="small" /></IconButton>
                                </>
                            )}
                            {editCounty && (
                                <>
                                    <Typography>County: </Typography>
                                    <Select value={county} onChange={(e) => { setCounty(e.target.value) }}>
                                        {counties.map((county) => (
                                            <MenuItem key={county.text}
                                                value={county.text}>
                                                {county.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton onClick={() => {
                                        updateAnswers("insuranceDetails.zipcode", zipcode);
                                        updateAnswers("insuranceDetails.county", counties.find(p => p.text === county)?.value);
                                        setPromptSent(false);
                                        setEditZipcode(false);
                                        setEditCounty(false);
                                    }}><Check fontSize="small" /></IconButton>
                                </>
                            )}
                            <IconButton onClick={() => { setEditZipcode(false); setEditCounty(false); }}><Cancel fontSize="small" /></IconButton>
                        </Box>
                    )}
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Date of Birth</TableCell>
                                <TableCell align="center">Gender</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(members).map(([key, member]) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {member.name}
                                    </TableCell>
                                    <TableCell align="center">{member.dob}</TableCell>
                                    <TableCell align="center">{member.gender}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="outlined" onClick={() => { 
                    setEditHouseholdOpen(true) }}>Update Household
                </Button>
                <Button variant="contained" disabled={promptSent} color="primary" onClick={async () => {
                    setPromptSent(true);
                    
                    const insuranceDetailsRef = doc(firestore, `users/${user?.uid}/answers/${user?.uid}`);
                    const newInsuranceDataSnap = await getDoc(insuranceDetailsRef);
                    const newInsuranceData = newInsuranceDataSnap.data()?.insuranceDetails || {};

                    await saveInsuranceData(oldInsuranceData, newInsuranceData);

                    await loadRates({
                        variables: {
                            effectiveDate: effectiveDate,
                            source: ideon ? "ideon" : "quotit"
                        },
                        fetchPolicy: "network-only"
                    });
                    sendPrompt("I have completed the form, please provide the recommended plans");
                }}>Continue</Button>
                {rateQuery.loading && <Typography>Refreshing Plans... <br /> <br /><CircularProgress /> </Typography>}
                <Dialog
                    open={editHouseholdOpen}
                    onAbort={() => { setEditHouseholdOpen(false); }}
                    fullWidth
                    maxWidth="lg"
                >
                    <Box sx={{
                        p: 4,
                        width: "100%"
                    }}>
                        <Household 
                        saved={async () => { 
                            const insuranceDetailsRef = doc(firestore, `users/${user?.uid}/answers/${user?.uid}`);
                            const oldInsuranceDataSnap = await getDoc(insuranceDetailsRef);
                            const oldInsuranceData = oldInsuranceDataSnap.data()?.insuranceDetails || {};
                            
                            const newInsuranceDataSnap = await getDoc(insuranceDetailsRef);
                            const newInsuranceData = newInsuranceDataSnap.data()?.insuranceDetails || {};
                            
                            await saveInsuranceData(oldInsuranceData, newInsuranceData);
                            setPromptSent(false); 
                            setEditHouseholdOpen(false);
                        }} 
                        cancel={() => { setEditHouseholdOpen(false) }} 
                    />                        
                    </Box>
                </Dialog>
            </Box>

            {/* Native American Check */}
            <br />
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                <FormLabel sx={{ fontWeight: 'bold', top: '10px' }}>Are you a Native American or Alaskan Native? &nbsp;</FormLabel>
                <RadioGroup
                    sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginLeft: { sm: 'auto' } }}
                    row
                    name="native"
                    value={native.toString()}
                    onChange={(e) => {
                        setNative(e.target.value === 'true');
                        setPromptSent(false);
                        updateAnswers("insuranceDetails.native", e.target.value === 'true');
                        if (e.target.value) {
                            setErrors(prevErrors => ({ ...prevErrors, native: '' }));
                        }
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
            {errors.native && <Typography color="error">{errors.native}</Typography>}

            {/* Premium Tax Credits Check */}
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                <FormLabel sx={{ fontWeight: 'bold', top: '10px' }}>Do you want to check for premium tax credits? &nbsp;</FormLabel>
                <RadioGroup
                    sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginLeft: { sm: 'auto' } }}
                    row
                    name="premiumTaxCredits"
                    value={checkPremiumTaxCredits.toString()}
                    onChange={(e) => {
                        setCheckPremiumTaxCredits(e.target.value === 'true');
                        setPromptSent(false);
                        updateAnswers("insuranceDetails.checkPremiumTaxCredits", e.target.value === 'true');
                        if (e.target.value) {
                            setErrors(prevErrors => ({ ...prevErrors, premiumTaxCredits: '' }));
                        }
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
            {errors.premiumTaxCredits && <Typography color="error">{errors.premiumTaxCredits}</Typography>}
        </Box>
    );
};

export default EnrollmentInformation;