import React from 'react';
import { Button } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { addDoc, collection } from "firebase/firestore";

const WhenBenefitCreator: React.FC = () => {
    const { firestore } = useFirebase();
    const { user } = useAuth();

    const createWhenBenefit = async () => {
        const whenBenefitData = {
            company: "When",
            companyId: "when",
            createdAt: new Date(),
            exitCode: "4BMjlWLAr4vCpsAz6FbN",
            maxBenefit: 5000,
            remainingBenefit: 3224,
            status: "active",
            terminationDate: "06/30/2024",
            time: {
                betweenRequests: {
                    measure: "months",
                    value: 6,
                },
                firstRequest: {
                    measure: "months",
                    value: 6,
                },
            },
            type: "Managed Health",
            updatedAt: new Date(),
        };

        const userId = user?.uid || "defaultUserId";
        const whenBenefitsRef = collection(firestore, `users/${userId}/whenBenefits`);

        try {
            if (!user) {
                console.error("User is not authenticated");
                return;
            }

            console.log(`Attempting to create When Benefit in collection: users/${userId}/whenBenefits`);
            const docRef = await addDoc(whenBenefitsRef, whenBenefitData);
            console.log("When Benefit created successfully with ID:", docRef.id, "and data:", whenBenefitData);
        } catch (error) {
            console.error("Error creating When Benefit:", error);
        }
    };

    return (
        <Button 
        onClick={createWhenBenefit} 
        sx={{ marginLeft: 2 }} 
    >
        Create When Benefit
    </Button>
    );
};

export default WhenBenefitCreator;