import React from 'react';
import { Button } from "@mui/material";
import { doc, setDoc, deleteField, Firestore } from "firebase/firestore";
import { User } from 'firebase/auth';

interface ResetInsuranceButtonProps {
  user: User | null;
  firestore: Firestore;
}

const ResetInsuranceButton: React.FC<ResetInsuranceButtonProps> = ({ user, firestore }) => {
  const resetInsurance = async () => {
    if (!user?.uid) {
      console.error("User is not authenticated");
      return;
    }

    try {
      await setDoc(doc(firestore, "users", user.uid, "answers", user.uid),
        {
          insuranceDetails: deleteField(),
        }, { merge: true });
      console.log("Insurance details reset successfully");
    } catch (error) {
      console.error("Error resetting insurance details: ", error);
    }
  };

  return (
    <Button 
      onClick={resetInsurance}
      variant="contained"
    >
      Reset Insurance
    </Button>
  );
};

export default ResetInsuranceButton;